import * as React from 'react';
import Grid from '@mui/material/Grid';
import './Charactor.css';
import DatasetTermData from './DatasetTerm.json'
import GeneratedVoiceTermData from './GeneratedVoiceTerm.json'



export default function Terms() {
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8} className="SimpleTabContentMargin">
          <div className="SimpleTabContent">
          <p >データセットの利用に際して以下の利用規約の遵守をお願いいたします。</p>
          <p >データセット規約</p>
          <textarea className="TermText" 
            // inputProps={{ "aria-disabled": true }} 
            value={DatasetTermData.text} 
            style={{width:600, height:500}}
            disabled/>
          <p >生成音声規約</p>
          <textarea className="TermText" 
            // inputProps={{ "aria-disabled": true }} 
            value={GeneratedVoiceTermData.text} 
            style={{width:600, height:500}}
            disabled/>
          </div>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </React.Fragment>
  );
}