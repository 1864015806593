import * as React from 'react';
import Grid from '@mui/material/Grid';
import DownloadForm from './DownloadForm';


export default function Download() {
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8} className="SimpleTabContentMargin">
          <div className="SimpleTabContent">現在データセットの無償配布は研究用途のみとなっています。下記フォームにて研究内容を添えて使用申請を行ってください。また、本データセットを用いた学習済みモデルの配布も行っております。学習済みモデルのご利用に関しましては、生成音声規約に従ってください。申請後20分のみデータセットへアクセス可能になります。データセットおよびモデルのダウンロードをもって、利用規約へ同意したものとみなします。</div>
          <DownloadForm/>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </React.Fragment>
  )
}