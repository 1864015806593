import './DownloadForm.css';
import { Button, Grid, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DataStore } from "@aws-amplify/datastore";
import { User } from '../models'; // <- Userモデルへのパスを正確に指定する必要があります。

interface DownloadFormProps {
  authData?: string;
}
const DownloadForm: React.FC<DownloadFormProps> = () => {
  const [isChecked, setChecked] = useState({ terms: false, twitter: false });
  const [purpose, setPurpose] = useState("");
  const [userID, setUserID] = useState("");
  const [oauth_token, setOauthToken] = useState("");
  const [oauth_verifier, setOauthVerifier] = useState("");
  const [s3DownloadUrl, setS3DownloadUrl] = useState("");
  const [s3RVCDownloadUrl, setS3RVCDownloadUrl] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  console.log(query);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({ ...isChecked, [event.target.name]: event.target.checked });
  };
  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPurpose(event.target.value);
  };
  React.useEffect(() => {
    setOauthToken(query.get('oauth_token') || "");
    setOauthVerifier(query.get('oauth_verifier') || "");
  }, [query.get('oauth_token'), query.get('oauth_token')]);

  React.useEffect(() => {
    if (oauth_token != null && oauth_verifier != null) {
      getUserID().then(userID => {
        if (userID) {
          console.log(userID);
          setUserID(userID);
          setChecked({ ...isChecked, ["twitter"]: true });
        }
      });
    }
  }, [oauth_token, oauth_verifier]); // oauth_token or oauth_verifierの変化を監視

  React.useEffect(() => {
    if (!isChecked.twitter || !isChecked.terms || !purpose) {
      setIsDownloading(true);
    } else {
      setIsDownloading(false);
    }
  }, [isChecked, purpose])


  async function getUserID() {
    const apiGateWayUrl = process.env.REACT_APP_GET_USER_ID_API_GATEWAY || 'http://localhost:3001/getUserID';
    const url = apiGateWayUrl + '?oauth_verifier=' + oauth_verifier + '&oauth_token=' + oauth_token;
    console.log(url)
    try {
      // const url = new URL(apiGateWayUrl);
      // url.searchParams.set('oauth_verifier', oauth_verifier || '');
      // url.searchParams.set('oauth_token', oauth_token || '');
      const response = await fetch(url);
      console.log(response);
      return response.text();
    } catch (error) {
      console.error(error);
    }
  }

  const signInWithTwitter = async () => {
    try {
      const apiGateWayUrl = process.env.REACT_APP_GET_AUTH_URL_API_GATEWAY || 'http://localhost:3001/getAuthURL';
      const response = await fetch(apiGateWayUrl).then((result) => result);
      console.log(response);
      const authURL = await response.text();
      console.log(authURL);
      open(authURL);
    } catch (error) {
      console.error(error);
    }
  };

  const saveUser = async (nameName: string) => {
    try {
      const user = await DataStore.save(
        new User({
          userName: nameName,
          downloadDate: new Date().toISOString()
        })
      );
      console.log('User saved successfully:', user);
    } catch (error) {
      console.error('Error saving todo:', error);
    }
  };

  const download = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    await saveUser(userID);
    await getDownloadURL(event);
    setIsDownloading(true);
  };


  async function getDownloadURL(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    // prevent default button click behavior
    event.preventDefault();

    // your API URL
    const apiURL = process.env.REACT_APP_GET_S3_URL_API_GATEWAY || 'http://localhost:3001/getS3URL';
    console.log(apiURL);

    try {
      // make a GET request to the API
      const response = await fetch(apiURL);
      // parse the response to JSON
      const data = await response.json();
      // here, let's assume the JSON response has a property "url" which is the download URL
      const downloadURL = data.url;

      setS3DownloadUrl(downloadURL);

      const rvcURL = data.rvc_url;
      setS3RVCDownloadUrl(rvcURL)
      // do something with downloadURL...

    } catch (error) {
      console.error(`Error fetching download URL: ${error}`);


    }
  }

  return (
    <form>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={2}></Grid>
        <Grid item xs={8} className="SimpleTabContentMargin">
          <div className='IrisiaForm'>
            <Stack direction="column" spacing={1}>
              <div>
                <label>
                  ダウンロードにはX(Twitter)へのログインが必要です
                  <Button onClick={signInWithTwitter}>
                    Sign In with
                    <img src="/XLogo.jpg" alt="X" style={{ height: '30px' }} />
                  </Button>
                  <input type="checkbox" name="twitter" checked={isChecked.twitter}/>
                </label>
              </div>
              <div>
                利用用途
              </div>
              <div>
                <label>
                  <textarea style={{ width: '400px', height: '50px' }} value={purpose} onChange={handleTextChange} required />
                </label>
              </div>
              <div>
                <label>
                  利用規約に同意する
                  <input type="checkbox" name="terms" checked={isChecked.terms} onChange={handleChange} />
                </label>
              </div>
              <div>
                <Button type="submit" disabled={isDownloading} onClick={download}>
                  ダウンロード申請
                </Button>
                <div>
                  {s3DownloadUrl &&
                    <a href={s3DownloadUrl} target="_blank" rel="noopener noreferrer">
                      <Button variant="contained" color="primary">
                        データセットをDownload
                      </Button>
                    </a>
                  }
                </div>
                <div>
                  {s3RVCDownloadUrl &&
                    <a href={s3RVCDownloadUrl} target="_blank" rel="noopener noreferrer">
                      <Button variant="contained" color="primary">
                        RVCモデルをDownload
                      </Button>
                    </a>
                  }
                </div>
              </div>
            </Stack>
          </div>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </form>
  );
}

export default DownloadForm;