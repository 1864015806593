import * as React from 'react';
import Grid from '@mui/material/Grid';

export default function Home() {
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8} className="SimpleTabContentMargin">
            <div className="SimpleTabContent">Irisia Projectは感情豊かな日本語表現を収録した音声関連研究用のデータセットを作成するプロジェクトです。
        本プロジェクトのデータセットを音声合成や声質変換など、音声関連の様々な研究、開発に活用していただければ幸いです。</div>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </React.Fragment>
  )
}