import React from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Header from './Header';
import Box from '@mui/material/Box';
import Charactor from './Charactor';
import Terms from './Terms';
import { Route, Routes, BrowserRouter, NavLink, useMatch} from 'react-router-dom';
import AboutUs from './AboutUs';
import Download from './Download';
import Home from './Home';
import { Button } from '@mui/material';

const theme = createTheme();

const CustomNavLink = ({ to, children }: { to: string, children: React.ReactNode }) => {
  const match = useMatch(to);

  return (
    <NavLink to={to} className={match ? 'active' : ''}>
      {children}
    </NavLink>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
       <div className="App">
        <Container maxWidth="lg" id='background'>
          <link 
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <Header/>
          <Box sx={{ width: '100%', typography: 'body1' }}>
          <div className='navMargin'>
            <nav style={{display: "flex", justifyContent: "space-between"}}>
              <Button><CustomNavLink to="/">TOP</CustomNavLink></Button>
              <Button><CustomNavLink  to="/charactor">CHARACTOR</CustomNavLink></Button>
              <Button><CustomNavLink  to="/about_us">About US</CustomNavLink></Button>
              <Button><CustomNavLink  to="/terms">Terms</CustomNavLink></Button>
              <Button><CustomNavLink  to="/download">DownLoad</CustomNavLink></Button>
            </nav>
          </div>
          <Routes>
          {/* メインコンテンツ */}
            <Route path="/about_us" element={<AboutUs/>}/>
            <Route path="/charactor" element={<Charactor/>}/>
            <Route path="/terms" element={<Terms/>}/>
            <Route path="/download" element={<Download/>}/>
            <Route path="/auth" element={<Download/>}/>
            <Route path="/"element={<Home/>}/>
          </Routes>
          </Box>
        </Container>
      </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
