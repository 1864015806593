import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TitleLogo from '../images/TitleLogoYoko.png'
import './Header.css'

export default function Header() {

  return (
    <React.Fragment>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'skyblue' , borderStyle: 'none none dashed none' }}>
      <div className="TitleLogo">
        <Typography
          align="center"
          sx={{ flex: 1 }}
        >
          <img src={TitleLogo}  height="120" />
        </Typography>
      </div>
        
      </Toolbar>
    </React.Fragment>
  );
}
