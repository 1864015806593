import * as React from 'react';
import Grid from '@mui/material/Grid';
import './AboutUs.css';
import contributors from './Contibutors.json'
const borderColorClasses = ['border-skyblue', 'border-yellow', 'border-lightpink'];

export default function AboutUs() {
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <div className="SimpleTabContentMargin">
            <div className="SimpleTabContent">私たち、Irisia Projectメンバーは有志の学生・エンジニア・研究者によって構成されています。
              非営利の活動でありながら、音声合成・声質変換に関わる研究・開発の手助けとなる新たなデータセットの作成と公開を目標にしています。
            </div>
          </div>
          <div className="SimpleTabContentMargin">
          <div className="SimpleTabContent"><h2>コントリビューター</h2></div>
          </div>
          {contributors.map((contributor) => (
            <div key={contributor.id} className='ContributorMargin'>
              <div className={`Contributor ${borderColorClasses[contributor.id % borderColorClasses.length]}`}>
                <div className="ContributorLeft">
                  <img src={contributor.imgSrc} alt={contributor.handleName}/>
                </div>
                <div className="ContributorRight">
                  <div className="ContributorInfo">
                    <h3>{contributor.handleName}</h3>
                    <p>ロール: {contributor.role}</p>
                    <p>Xアカウント: {contributor.account}</p>
                    <p>得意分野: {contributor.expertise}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </React.Fragment>
  );
}